import { FC, memo, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

type LabelPosition = 'left' | 'right' | 'top' | 'bottom'

interface InputProps {
  caption?: string;
  rightAlignCaption?: string;
  className?: string;
  error?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  label?: string;
  labelPosition?: LabelPosition;
  placeholder?: string;
  required?: boolean;
  type?: 'text' | 'email' | 'number' | 'password' | 'tel' | 'url';
  value?: string | number;
  readonly?: boolean;
  disabled?: boolean;
  withBottomOffset?: boolean;
  name?: string;
  size?: number;
  onClick?: () => void;
  onChange?: (inputValue: string, inputName?: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onRightIconClick?: () => void;
  onSubmit?: (inputValue: string) => void;
  backgroundColor?: string
  containerMargin?: string;
  borderRadius?: string;
}

const Input: FC<InputProps> = ({
  caption = '',
  rightAlignCaption = '',
  className,
  error,
  leftIcon,
  rightIcon,
  label,
  labelPosition = 'top',
  required,
  type = 'text',
  placeholder,
  value = '',
  name,
  size = 1,
  readonly = false,
  disabled = false,
  withBottomOffset,
  onClick,
  onChange,
  onFocus,
  onBlur,
  onRightIconClick,
  onSubmit,
  backgroundColor,
  containerMargin,
  borderRadius
}) => {
  const [ cursor, setCursor ] = useState(null)
  const ref = useRef(null)

  useEffect(() => {
    const input = ref?.current
    if (input && type === 'text') input.setSelectionRange(cursor, cursor)
  }, [ ref, cursor, value, type, name ])

  const inputClasses = [
    error ? 'input-error' : '',
    rightIcon ? 'input-with-icon-right' : '',
    leftIcon ? 'input-with-icon-left' : '',
  ].join(' ')

  const labelClasses: Record<LabelPosition, string> = {
    top: 'input-label-top',
    right: 'input-label-right',
    left: 'input-label-left',
    bottom: 'input-label-bottom'
  }

  const onInputChange = (event) => {
    const inputValue = event?.target?.value || ''
    setCursor(event?.target?.selectionStart)
    onChange(type === 'number' && parseFloat(inputValue) < 0? 0: inputValue, name)
  }

  const onInputSubmit = (event) => {
    if(event.key !== 'Enter') return null
    const inputValue = event?.target?.value || ''
    onSubmit(inputValue)
  }

  return (
    <Container
      className={className + ' main-input-container'}
      $withBottom={withBottomOffset && !error && !caption && !rightAlignCaption}
      containerMargin={containerMargin}
    >
      {
        label ? (
          <StyledLabel className={labelClasses[labelPosition]} required={required}>
            <span className="center">{label}</span>
          </StyledLabel>
        ) : null
      }

      <ContainerInput>
        {
          leftIcon ? (
            <ContainerIcon className="left-icon">
              {leftIcon}
            </ContainerIcon>
          ) : null
        }
        <StyledInput
          ref={ref}
          className={inputClasses}
          type={type}
          placeholder={placeholder}
          value={value}
          readOnly={readonly}
          onClick={onClick}
          onChange={onInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          name={name}
          key={name}
          onKeyDown={onInputSubmit}
          size={size}
          backgroundColor={backgroundColor}
          borderRadius={borderRadius}
        />
        {
          rightIcon ? (
            <ContainerIcon className="right-icon" onClick={onRightIconClick}>
              {rightIcon}
            </ContainerIcon>
          ) : null
        }
      </ContainerInput>

      {error ? (<StyledErrorText>{error}</StyledErrorText>) : null}
      {
        !error && (caption || rightAlignCaption) ?
          (<StyledCaptionText className="input-captions">
            <span>{caption}</span>
            <span className="right-align-caption">{rightAlignCaption}</span>
          </StyledCaptionText>) :
          null
      }
    </Container>
  )
}

const Container = styled.div<{ $withBottom?: boolean, containerMargin: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${({ containerMargin }) => containerMargin ? containerMargin : '1.5rem'} 0;
  ${props => props.$withBottom ? 'padding-bottom: 25px' : ''}
`

const StyledLabel = styled.label<{required: boolean}>`
  margin-bottom: 10px;
  color: var(--r-color-gray-darker);
  font-size: var(--font-size-extra-small);
  line-height: var(--font-size-small);
  ${(props) => props.required && css`&:after {content: " *"; color: var(--r-color-gray-darker)}`}
  &.input-label-right {
    border-left: 1px solid var(--color-gray-intermidiate);
    margin-bottom: 5px;
    position: relative;
    width: 30%;
    left: 70%;
    display: flex;
    top: 2.5rem;
    padding: 0 6px;
    z-index: 9999999999999;
    @media (min-width:600px)  {
      left: 85%;
      width: 15%
    }
  }
  &.input-label-left {}
  &.input-label-top {
    display: inline-flex;
  }
  &.input-label-bottom {}
  .center {
    text-align: center;
    display: inline-block;
    width: 100%;
    font-size: var(--font-size-extra-small);
  }
`

const ContainerInput = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
`

const StyledInput = styled.input<{backgroundColor: string, borderRadius: string}>`
  background: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'var(--r-color-white)'} ;
  border: 1px solid var(--r-color-white);
  border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '4px'};
  height: 52px;
  outline: none;
  padding: 14px;
  width: 100%;
  font-size: var(--font-size-normal);
  text-overflow: ellipsis;

  ::placeholder {
    line-height: 24px;
    color: var(--r-color-gray-inactive);
    font-size: var(--font-size-normal);
  }
  &.input-error {
    border-bottom: 1px solid var(--color-warning);
  }
  &.input-with-icon-right {
    padding: 1rem 2.6rem 1rem 1rem;
  }
  &.input-with-icon-left {
    padding: 1rem 1rem 1rem 54px;
  }
`

const ContainerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  position: absolute;

  &.left-icon {
    left: 14px;
  }
  &.right-icon {
    right: .6rem;
  }
`

const StyledErrorText = styled.span`
  color: var(--color-warning);
  font-size: var(--font-size-extra-small);
  height: 25px;
`

const StyledCaptionText = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--r-color-gray-darker);
  font-size: var(--font-size-extra-small);

  .right-align-caption {
    color: var(--color-gray-darker);
  }
`

export default memo(Input)
