import { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import { sendSignInLinkToEmail } from 'firebase/auth'
import { Screen } from '@app/components'
import { Button, Divider, Icon, IconColor, IconSize, IconTypes, Popup } from '@app/ui'
import { Trans, useTranslation } from 'react-i18next'
import { DomainTitleComponent } from './Entry'
import Input from '@app/ui/Input'
import { ButtonSize, ButtonType } from '@app/types'
import { getFirebaseAuth } from '@app/firebase'
import { ROUTES } from '@app/constants'
import { useNavigate } from 'react-router-dom'
import { CurrentUserState } from '@app/storage'

interface MagicLinkScreenProps { }

const MagicLinkScreen: FC<MagicLinkScreenProps> = () => {

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [ emailText, setEmailText ] = useState('')
  const [ showPopup, setShowPopup ] = useState(false)

  const onEmailChange = (text = '') => setEmailText(text)

  const actionCodeSettings = {
    url: `${window.location.protocol}//${window.location.host}?email=${encodeURIComponent(emailText)}`,
    handleCodeInApp: true,
  }

  const handleSendMagicLink = async () => {
    const auth = getFirebaseAuth()
    if (!auth) {
      console.error('Firebase Auth is not initialized.')
      return
    }
    try {
      console.log('actionCodeSettings: ', actionCodeSettings)
      await sendSignInLinkToEmail(auth, emailText, actionCodeSettings)
      localStorage.setItem('emailForSignIn', emailText)
      localStorage.setItem('auth_type', 'magic_link')
      CurrentUserState.initiateOnboarding = true

      if (emailText.length > 0) {
        localStorage.setItem('magic_link_email', emailText)
        setShowPopup(true)
      }

    } catch (error) {
      console.error('Error sending magic link:', error)
    }
  }

  useEffect(() => {
    getFirebaseAuth()
  }, [])


  const handleClosePopUp = () => {
    navigate(ROUTES.magicLinkSent.short)
    setShowPopup(false)

  }
  return (
    <>
      <LoginScreenStyled title={t('login.authorization')}>
        <Divider size="logoTopSpace" />
        <ImageIcon className="logotype" src="/assets/branding/logotype-entry-x.png" alt="Votebetting" />
        <DomainTitleComponent />
        <Divider size="normal" />

        <CreateAccount>{t('auth.createYourAccount')}</CreateAccount>
        <Input
          label={t('common.email')}
          placeholder={t('auth.enterEmail')}
          value={emailText}
          required
          onChange={onEmailChange}
          containerMargin={'0.2rem'}
        />
        <Divider size="small" />
        <MagicLinkDescription>{t('auth.useMagicLink.description')}</MagicLinkDescription>
        <Divider size="big" />
        <Button
          size={ButtonSize.big}
          type={ButtonType.buttonColor}
          fluid
          onClick={handleSendMagicLink}
          textColor="var(--r-color-white)"
        >
          <ButtonContent>
            <Icon type={IconTypes.magicLinkIcon} color={IconColor.white} size={IconSize.small} />
            <p><Trans>{t('auth.magicLink.send')}</Trans></p>
          </ButtonContent>
        </Button>
        <Divider size="big" />


        <Divider expanded />
        <ButtonContainer>
          <Icon type={IconTypes.angleLeft}
            color={IconColor.buttonColor}
            size={IconSize.small} />
          <Button
            onClick={() => navigate(-1) }
            type={ButtonType.transparent}>
            <p>Back</p>
          </Button>
        </ButtonContainer>
      </LoginScreenStyled>

      <Popup
        open={showPopup}
        onClose={handleClosePopUp}
        closeButtonText={t('check.inbox')}
        otherMessageParam={t('check.inbox.description', { text: 'send again' })}
        otherMessageParamOnClick={() => console.log('Check me')}
        buttonFluid={true}
        headerText={'CHECK YOUR EMAIL'}
      >
        <p>{t('validation.weEmailMagicLink', { email: emailText })}</p>
      </Popup>
    </>
  )
}

const LoginScreenStyled = styled(Screen)`
  text-align: center;
  background-color: var(--r-color-background-gray);

  .logotype {
    width: 180px;
    display: block;
    margin: auto;
  }
  .incognito-icon {
    width: 32px;
  }
  iframe {
    display: none !important;
  }
`

const StyledLoginButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .login-button-block {
    width: 30%;
    min-width: 140px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .login-button-text {
    font-size: var(--font-size-big);
    color: var(--font-color-primary);
    margin-left: 1rem;
  }
`

const ImageIcon = styled.img`
  padding: 20px;

`
const LoginAction = styled.p`
  padding: 20px 0;
`

const DomainTitle = styled.p`
  font-weight:  900;
  font-style:   italic;
  font-size:    30.41px;
  font-family:  Nunito-Bold;
  line-height:  34.65px; 

`
const MagicLinkDescription = styled.p`
  text-align: left;
  font-size: var(--font-size-extra-small);
`
const CreateAccount = styled.p`
  text-align: center;
  font-size: var(--font-size-big);
  color: var(--r-color-gray-darker);
  font-weight: 700;
  padding: 20px 0;
`
const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    padding-left: 10px;
      font-size: var(--font-size-medium);
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: var(--font-size-medium);
    font-weight: 900;
  }
`
export default MagicLinkScreen


