import { ContactFormReceiversEnum } from '@app/types'
import i18n from '@app/libraries/i18n'

export const contactFormReceivers = [
  {
    id: ContactFormReceiversEnum.tipster,
    title: i18n.t('common.tipster'),
    selected: false,
  },
  {
    id: ContactFormReceiversEnum.appSupport,
    title: i18n.t('menu.appSupport'),
    selected: false,
  },
]

export const SUPPORT_EMAIL = 'support@votebetting.app'

export const LOGOTYPE_PNG_IMAGE_WITH_CAPTION_SRC = '/assets/branding/logotype-entry.png'
export const LOGOTYPE_WEBP_IMAGE_WITH_CAPTION_SRC = '/assets/branding/logotype-entry.webp'
export const BACKGROUND_ENTRY_WEBP_IMAGE_SRC = '/assets/images/entry-background.webp'
export const BACKGROUND_ENTRY_PNG_IMAGE_SRC = '/assets/images/entry-background.png'
export const LOGOTYPE_IMAGE_SRC = '/assets/branding/logotype-entry-x.png'
export const INCOGNITO_IMAGE_SRC = '/assets/images/incognito-avatar.png'
export const SUBMIT_IMAGE_SRC = '/assets/images/submit.png'
export const TIPSTER_ENTRY_IAM_PNG_IMAGE_SRC = '/assets/images/tipster_entry_am.png'
export const USER_ENTRY_IAM_PNG_IMAGE_SRC = '/assets/images/user_entry_am.png'
export const CHANNEL_NO_PROFILE_PNG_IMAGE_SRC = '/assets/images/ChannelNoImage.png'
export const USER_NO_PROFILE_PNG_IMAGE_SRC = '/assets/images/ProfileNoImage.png'
export const FIVE_STARS_REVIEW_IMAGE_SRC = '/assets/images/stars.png'
export const SOCCER_SRC = '/assets/branding/soccer.png'
export const TENNIS_SRC = '/assets/branding/tennis.png'
export const BASKETBALL_SRC = '/assets/branding/basketball.png'
