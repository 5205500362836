import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Portal from './Portal'
import { ModalType } from '@app/types'
import { useScrollLock } from '@app/helpers/hooks'

interface ModalProps {
  open: boolean;
  locked?: boolean;
  className?: string;
  type?: ModalType;
  children?: React.ReactNode;
  onClose: () => void;
  standardBackground?: string;
  zIndex?: number;
}

const Modal: FC<ModalProps> = ({
  open,
  locked,
  className = '',
  type: modalType = ModalType.standard,
  children,
  onClose,
  standardBackground,
  zIndex = 2
}) => {
  const [active, setActive] = useState(false)
  const { lockScroll, unlockScroll } = useScrollLock()
  const backdrop = useRef(null)

  useEffect(() => {
    const { current } = backdrop
    const rootElement = document.getElementById('root')
    const transitionEnd = () => setActive(open)
    const clickHandler = (e) => !locked && e.target === current && onClose()

    if (current) {
      current.addEventListener('transitionend', transitionEnd)
      current.addEventListener('click', clickHandler)
    }

    if (open) {
      window.setTimeout(() => {
        lockScroll()
        document.activeElement instanceof HTMLElement && document.activeElement.blur()
        setActive(open)
        rootElement.setAttribute('inert', 'true')
      }, 10)
    } else {
      unlockScroll()
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd)
        current.removeEventListener('click', clickHandler)
      }

      rootElement.removeAttribute('inert')
    }
  }, [open, locked])

  const backdropClasses = className + ' ' + (active && open ? 'active' : '') + ` type-${modalType}`
  const contentClasses = 'modal-content ' + `type-${modalType}`

  return (
    <>
      {
        (open || active) && (
          <Portal>
            <StyledBackdrop zIndex={zIndex} ref={backdrop} className={backdropClasses} standardBackground={standardBackground}>
              <div className={contentClasses}>{children}</div>
            </StyledBackdrop>
          </Portal>
        )
      }
    </>
  )
}

const StyledBackdrop = styled.div<{ zIndex: number; standardBackground: string }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({zIndex}) => zIndex};

  &.type-app-menu {
    justify-content: flex-start;
    z-index: 3
  }

  &.type-context-menu {
    align-items: end;
  }

  &.type-header-search {
    align-items: flex-start;
    padding-top: 10px;
  }

  &.type-standard, &.type-fullscreen, &.type-context-menu {
    .modal-content {
      transform: translateY(100px);
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
    }

    &.active {
      height: 100%;
      transition-duration: 250ms;
      transition-delay: 0ms;
      opacity: 1;

      & .modal-content {
        transform: translateY(0);
        opacity: 1;
        transition-delay: 150ms;
        transition-duration: 350ms;
      }
    }
  }

  &.image-preview {
    & .type-fullscreen {
      background: black;
    }
  }
  &.create-post {
    & .type-fullscreen {
      background: white;
      // padding: 0.5rem 0.5rem;
      width: 100vw;
      height: 100vh;
    }
  }

  &.active {
    opacity: 1;
  }

  .type-standard {
    position: relative;
    padding: 2rem 1rem 1rem;
    min-height: 50px;
    width: 90%;
    max-height: 80%;
    max-width: 580px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: ${({ standardBackground }) => standardBackground ? standardBackground : 'white'};
    border-radius: 1rem;
  }

  .type-fullscreen {
    background-color: white;
    height: 100%;
    width: 100%;
    max-width: 580px;
  }

  .type-app-menu {
    background-color: white;
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: column;
  }

  .type-header-search {
    // padding: 0.5rem 0.5rem 0;
    min-height: var(--standard-header-height);
    width: 100%;
  }

  .type-context-menu {
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    width: 100%;
  }
  
  &.create-post {
    color: var(--color-gray-darker);
  }
  &.create-poll {
    color: var(--color-gray-darker);
  }
  &.create-tip {
    color: var(--r-color-gray-darker);
  }
`

export default Modal
