export enum misc {
    FOOTBALL = 'Football',
    SOCCER = 'Soccer',
}

export enum currentView {
    POST_TIP = 'POST_TIP',
    POST_TIP_1 = 'POST_TIP_1',
    POST_TIP_2 = 'POST_TIP_2',
    POST_TIP_NON = 'POST_TIP_NON',
}

export enum currentViewTitle {
    CREATE_POST = 'Create Post',
    CREATE_POST_TIP_1 = 'Create Post Tip 1/2',
    CREATE_POST_TIP_2 = 'Create Post Tip 2/2',
    UPDATE_POST_TIP_1 = 'Update Post Tip 1/2',
    UPDATE_POST_TIP_2 = 'Update Post Tip 2/2',
    UPDATE_POST = 'Update Post',
}
export enum FIELD_CHECK {
    TITLE = 'title',
    SPORT_ID = 'sportId',
}
export enum PostTypes {
    REGULAR_POST = 'Regular_Post',
    SINGLE_POST_TIP = 'Single_PostTip',
    POST_TIP = 'Post_Tip',
}
export enum PostTipTypeEnum {
    Regular_Post = 'Regular_Post',
    Post_Tip = 'Post_Tip',
}
export enum PaymantTypeEnum {
    Automatic = 'Automatic',
    Manual = 'Manual',
}
export enum BookieTypeEnum {
    Custom_Odds = 'Custom_Odds',
    Verified_Odds = 'Verified_Odds',
}
export enum TipTypeEnum {
    Data_Tip = 'Data_Tip',
    Image_Tip = 'Image_Tip',
}

export enum UserTypeEnum {
    User = 'User',
    Tipster = 'Tipster',
}

export const changeSportNameToMatchIcon = (inputString)=> {
  const words = inputString?.split(' ')
  const camelCaseString = words?.map((word, index) => {
    if(word === 'Esports'){
      return 'eSports'
    }
    else if (index === 0) {
      return word.toLowerCase()
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
  }).join('')
  return camelCaseString
}

export  const fileExtensions = [ 'JPG', 'PNG', 'GIF', 'JPEG', 'SVG' ]
