import React, { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Hub } from 'aws-amplify'
import { Messaging } from 'firebase/messaging'
import { Toaster } from 'react-hot-toast'
import { useSnapshot } from 'valtio'
import {
  PublicChannelState,
  resetCurrentUserState,
} from '@app/storage'

// flag icons import
import '/node_modules/flag-icons/css/flag-icons.min.css'

// Screens
import { EntryScreen } from './screens'
import { AFFILIATE_LINK_INTERNAL_NAME_KEY,
  FOLLOW_TIPSTER_LINK_INTERNAL_NAME_KEY,
  INVITE_LINK_INTERNAL_NAME_KEY, INVITEE_TIPSTER_LINK_INTERNAL_NAME_KEY, ROUTES,
  USER_NO_PROFILE_PNG_IMAGE_SRC } from '@app/constants'
import {
  DeleteFirebaseTokenDocument,
  FollowChannelByLinkNameDocument,
  GetCountryByIpDocument,
  GetCurrentUserDocument,
  SendFirebaseTokenDocument,
  TipsterStatus,
  UserPermission,
  UserRoles,
  UserStatus,
  FollowUserByLinkNameDocument,
  UserOnboardingStages,
} from '@app/graphql'
import {
  CurrentUserState,
  resetAppUpdateState,
  UserAuthState,
  CurrentCountryState,
  PrivateChannelState
} from '@app/storage'
import { AuthHelper, UserAgentHelper } from '@app/helpers'
import { AppRoutesType, BeforeInstallPromptEvent } from '@app/types'
import { InstallAppContext } from './context'
import { getFirebaseMessaging, handleForegroundMessages, loadToken } from './firebase'
import i18n from './libraries/i18n'
import posthog from 'posthog-js'
import { isInstalledApp } from './helpers/userAgent'
import { OnboardingAccountSwitcher, Popup, SignupPromptModal } from './ui'
import { onboardingRedirectUrl } from './helpers/auth'
import NotificationsSettingsDetailsScreen from './screens/NotificationsSettingsDetails'
import MagicLinkScreen from './screens/MagicLink'
import MagicLinkSentScreen from './screens/MagicLinkSent'

// Components lazy loading
const LoginRoutes = lazy(() => import('@app/screens/Login/LoginRoutes'))
const TipsterOnboardingRoutes = lazy(() => import('@app/screens/Login/TipsterOnboarding'))
const PublicChannelScreen = lazy(() => import('@app/screens/Channels/PublicChannel'))
const PrivateChannelScreen = lazy(() => import('@app/screens/Channels/PrivateChannel'))
const CommentsScreen = lazy(() => import('@app/screens/Comments/Comments'))
const AppSettingsScreen = lazy(() => import('@app/screens/AppSettings'))
const ContactFormScreen = lazy(() => import('@app/screens/ContactForm'))
const MatchesListScreen = lazy(() => import('@app/screens/Matches/MatchesList'))
const SavedTipsScreen = lazy(() => import('@app/screens/SavedTips'))
const NotificationsSettingsScreen = lazy(() => import('@app/screens/NotificationsSettings'))
const InboxScreen = lazy(() => import('@app/screens/Inbox/Inbox'))
const Profile = lazy(() => import('@app/screens/Profile'))
const ChannelProfileScreen = lazy(() => import('@app/screens/ChannelProfile/ChannelProfile'))
const MatchesVotedScreen = lazy(() => import('@app/screens/MatchesVoted'))
const BannedScreen = lazy(() => import('@app/screens/Banned'))
const BlacklistedCountryScreen = lazy(() => import('@app/screens/BlackListedCountry'))
const PaymentScreen = lazy(() => import('@app/screens/Payment/Payment'))
const PaymentResultScreen = lazy(() => import('@app/screens/Payment/PaymentResult'))
const LegalScreen = lazy(() => import('@app/screens/Legal/Legal'))
const ProtectedRoute = lazy(() => import('@app/components/ProtectedRoute'))
const Screen = lazy(() => import('@app/components/Screen/Screen'))
const UserSubscriptions = lazy(() => import('@app/screens/UserSubscriptions'))
const TipsterSubscriptions = lazy(() => import('@app/screens/TipsterSubscriptions'))
const TipsterSubscribersList = lazy(() => import('@app/screens/TipsterSubscribersList'))
const InstallScreen = lazy(() => import('@app/screens/InstallPage'))

// Initialize Language
function initLanguage() {
  const userLanguage = localStorage.getItem('app_language') || 'en'
  UserAuthState.userLanguageId = userLanguage
  i18n.changeLanguage(userLanguage)
}
initLanguage()

interface ApplicationProps {}

const Application: React.FC<ApplicationProps> = () => {
  const [ installEvent, setInstallEvent ] = useState<{ event: BeforeInstallPromptEvent }>({ event: null })
  const [ loading, setLoading ] = useState(true)
  const [ hasError, setHasError ] = useState(false)
  const [ firebaseToken, setFirebaseToken ] = useState('')
  const [ firebaseMessaging, setFirebaseMessaging ] = useState<Messaging>()
  const [ getUser, { data: userData, loading: userLoading, error: userError } ] = useLazyQuery(GetCurrentUserDocument)
  const [ getCountryByIp, { data: countryData, loading: countryLoading } ] = useLazyQuery(GetCountryByIpDocument)
  const [ sendFirebaseToken ] = useMutation(SendFirebaseTokenDocument)
  const [ deleteFirebaseToken ] = useMutation(DeleteFirebaseTokenDocument)
  const [ followChannelByLinkName ] = useMutation(FollowChannelByLinkNameDocument)
  const [ followUserByLinkName ] = useMutation(FollowUserByLinkNameDocument)
  const swRegistration = useRef<ServiceWorkerRegistration>(null)
  const {
    auth_type,
    hasOnboarded,
    id,
    guestMode,
    ownedChannels,
    userType,
    banned,
    initiateOnboarding,
    onboardingStage
  } = useSnapshot(CurrentUserState)
  const userAuthState = useSnapshot(UserAuthState)
  if([ `${window.location.pathname}/`,`${window.location.pathname}` ].includes(ROUTES.tipsterOnboarding.short)) {
    localStorage.setItem('onboardingFlow', UserRoles.Tipster)
  }

  useEffect(() => {
    getCountryByIp()
    if(countryData && !countryLoading) {
      CurrentCountryState.id = countryData?.countryByIp?.id
      CurrentCountryState.name = countryData?.countryByIp?.name
      CurrentCountryState.code = countryData?.countryByIp?.code
      CurrentCountryState.continent = countryData?.countryByIp?.continent
      CurrentCountryState.isBlacklisted = countryData?.countryByIp?.isBlacklisted
      UpdateLangByCountry()
    }

    if(countryData?.countryByIp?.isBlacklisted) {
      setLoading(countryLoading)
    }
  }, [ countryData ])

  useEffect(() => {
    setFirebaseMessaging(getFirebaseMessaging())

    return () => {
      if (firebaseToken) {
        deleteFirebaseToken({ variables: { token: firebaseToken } })
      }
    }
  }, [ firebaseToken ])

  useEffect(() => {
    // BEFORE INSTALL APP EVENT LISTENER
    window.addEventListener('beforeinstallprompt', (e: Event) => {
      e.preventDefault()
      setInstallEvent({ event: e as BeforeInstallPromptEvent })
    })
    // GET SEARCH PARAMS
    const { affiliateLinkName, inviteLinkName, inviteeTipsterLinkName, installLinkName } = AuthHelper.getSearchParams()
    UserAuthState.affiliateLinkName = affiliateLinkName
    UserAuthState.inviteLinkName = inviteLinkName
    UserAuthState.inviteeTipsterLinkName = inviteeTipsterLinkName
    // todo: temporary forbid to create new users
    // AFFILIATE LINK HANDLING
    return Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'customOAuthState' && data) {
        const queryParams = decodeURIComponent(data)

        try {
          setLoading(true)
          if (queryParams) {
            const param = JSON.parse(queryParams)
            UserAuthState.affiliateLinkName = param?.[AFFILIATE_LINK_INTERNAL_NAME_KEY]
            UserAuthState.inviteLinkName = param?.[INVITE_LINK_INTERNAL_NAME_KEY]
            UserAuthState.followLinkName = param?.[FOLLOW_TIPSTER_LINK_INTERNAL_NAME_KEY]
            UserAuthState.inviteeTipsterLinkName = param?.[INVITEE_TIPSTER_LINK_INTERNAL_NAME_KEY]
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }, [])

  const posthogUser = () => {
    const user = userData?.userCurrent ?? {}
    return {
      email: user.emailAddress ?? userAuthState.userEmail,
      username: user.displayName ?? userAuthState.userAppName,
      firstName: user.firstName ?? userAuthState.firstName,
      lastName: user.lastName ?? userAuthState.lastName,
      role: user.role ?? null,
      onboardingStage: user.onboardingStage ?? null,
      phone: user.phoneNumber ?? null,
      gender: user.gender ?? null,
      authType: auth_type,
      appViewType: isInstalledApp()
    }
  }

  useEffect(() => {
    const checkSocialUser = async () => {
      const userData = await AuthHelper.getCurrentUser()
      if (userData?.data && !userData?.error) CurrentUserState.authBySocial = true
    }
    window.addEventListener('resize', setAppHeight)
    getUser()
    checkSocialUser()
    setAppHeight()
    checkWebpSupport()
  }, [])

  useEffect(() => {
    const registration = swRegistration.current
    if (registration && firebaseMessaging) {
      loadFirebaseToken(firebaseMessaging, registration)
      handleForegroundMessages(firebaseMessaging)

      if (registration.waiting) {
        console.log('NEW VERSION DETECTED')
        updateServiceWorker()
      }
      return
    }

    setSWRegistration()
  }, [ swRegistration.current, firebaseMessaging ])

  useEffect(() => {
    if (firebaseToken) sendFirebaseToken({ variables: { token: firebaseToken } })
  }, [ firebaseToken ])

  useEffect(() => {
    if(userData?.userCurrent){
      const user = userData.userCurrent
      posthog.identify(
        user.id,
        posthogUser()
      )
    } else  {
      posthog.identify(
        UserAuthState.userAppName ?? 'distinct_id',
        posthogUser()
      )
    }
  }, [ userData, UserAuthState ])

  useEffect(() => {
    const load = async () => {
      try {
        PublicChannelState.adminJoinDisabled = false
        if (userData?.userCurrent) {
          const user = userData.userCurrent
          const userMuted = user.role === UserRoles.User && user.userStatus === UserStatus.Muted
          const userBanned = user.role === UserRoles.User && user.userStatus === UserStatus.Banned
          const tipsterBanned = user.role === UserRoles.Tipster && user.tipsterStatus === TipsterStatus.Inactive
          const permsSet = new Set(user.permissions)
          if (userBanned || tipsterBanned) {
            CurrentUserState.banned = true
          } else {
            CurrentUserState.muted = userMuted
            CurrentUserState.onboardingStage = user.onboardingStage
            CurrentUserState.birthday = user.dateOfBirth ? new Date(user.dateOfBirth) : null
            CurrentUserState.countryId = user.country
            CurrentUserState.id = user.id
            CurrentUserState.name = user.firstName
            CurrentUserState.phone = user.phoneNumber
            CurrentUserState.surname = user.lastName
            CurrentUserState.userEmail = user.emailAddress
            CurrentUserState.userType = user.role
            CurrentUserState.username = user.displayName
            CurrentUserState.avatarUrl = user.avatarUrl ?? USER_NO_PROFILE_PNG_IMAGE_SRC
            CurrentUserState.profileBackgroundUrl = user.background
            CurrentUserState.gender = user.gender
            CurrentUserState.bio = user.bio
            CurrentUserState.unitShare = user.unitShare
            CurrentUserState.unitValue = user.unitValue
            CurrentUserState.countryId = user.countryId
            CurrentUserState.permAddPost = permsSet.has(UserPermission.AddPost)
            CurrentUserState.permEditPost = permsSet.has(UserPermission.EditPost)
            CurrentUserState.ownedChannels = (
              user?.channelsWhereUserIsTipster ?? []
            ).map(({ id, logo, channelName }) => ({ id, channelName, logo }))
            CurrentUserState.hasPendingSubscription = userData?.hasPendingSubscription ?? false
            await followChannelByLink()
            await followTipsterByLink()
          }
        }
      } finally {
        // eslint-disable-next-line quotes
        if(userError && userError?.message.includes("does't exist")) {
          CurrentUserState.initiateOnboarding = true
        }
        if(userError && (userError?.message.includes('connect ECONNREFUSED') && userError?.message.includes('Forbidden resource'))) {
          setHasError(!hasError)
        }
        if (!userLoading && (userData || userError)) {
          setLoading(false)
        }
      }
    }
    load()
  }, [ userData, userLoading, userError ])

  const UpdateLangByCountry = useCallback(() => {
    let userLanguage = localStorage.getItem('app_language')
    if(userLanguage) return
    switch (CurrentCountryState.name) {
      case ['Slovenia'].find((name) => name.toLowerCase() == CurrentCountryState?.name.toLowerCase()):
        userLanguage = 'si'
        break
      case ['Bosnia', 'Croatia', 'Serbia'].find((name) => name.toLowerCase() == CurrentCountryState?.name.toLowerCase()):
        userLanguage = 'sr'
        break
      case ['Republic of North Macedonia'].find((name) => name.toLowerCase() == CurrentCountryState?.name.toLowerCase()):
        userLanguage = 'mk'
        break
      case ['Austria', 'Germany'].find((name) => name.toLowerCase() == CurrentCountryState?.name.toLowerCase()):
        userLanguage = 'de'
        break
      case ['Italy'].find((name) => name.toLowerCase() == CurrentCountryState?.name.toLowerCase()):
        userLanguage = 'it'
        break
      case [
        'France', 'Morocco', 'Algeria', 'Tunisia', 'Lebanon', 'Benin', 'Burkina Faso', 'Burundi', 'Cameroon', 'Chad', 'Ivory Coast', 'Democratic Republic of the Congo', 'Djibouti',
        'Equatorial Guinea', 'Haiti', 'Madagascar', 'Niger', 'Rwanda', 'Senegal', 'Togo', 'Seychelles'
      ].find((name) => name.toLowerCase() == CurrentCountryState?.name.toLowerCase()):
        userLanguage = 'fr'
        break

      case ['Spain', 'Mexico', 'Costa Rica', 'El Salvador', 'Guatemala', 'Honduras', 'Nicaragua', 'Panama', 'Cuba', 'Dominican Republic', 'Puerto Rico', 'Argentina', 'Bolivia', 'Chile',
        'Colombia', 'Ecuador', 'Paraguay', 'Peru', 'Uruguay', 'Venezuela', 'Equatorial Guinea'].find((name) => name.toLowerCase() == CurrentCountryState?.name.toLowerCase()):
        userLanguage = 'es'
        break
      case ['Brazil', 'Angola',' Cabo Verde', 'Guinea-Bissau', 'Equatorial Guinea', 'Mozambique', 'Portugal', 'Sao Tome and Principe',  'Timor-Leste'].find((name) => name.toLowerCase() == CurrentCountryState?.name.toLowerCase()):
        userLanguage = 'pt'
        break
      default:
        userLanguage = 'en'
    }
    UserAuthState.userLanguageId = userLanguage
    localStorage.setItem('app_language', userLanguage)
    i18n.changeLanguage(userLanguage)
  }, [countryData])

  const setSWRegistration = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        swRegistration.current = registration
      })
    }
  }

  const loadFirebaseToken = async (messaging: Messaging, registration: ServiceWorkerRegistration) => {
    if (firebaseToken) {
      return
    }

    try {
      const token = await loadToken(messaging, registration)
      setFirebaseToken(token)
    } catch (e) {
      console.error(e)
    }
  }

  const setAppHeight = () => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  const checkWebpSupport = () => {
    UserAgentHelper.checkWebpSupport((result: boolean) => UserAuthState.webpSupported = result)
  }

  const updateServiceWorker = () => {
    const registration = swRegistration.current
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      resetAppUpdateState()
    }
  }

  const followChannelByLink = async () => {
    try {

      const { affiliateLinkName, inviteLinkName } = AuthHelper.getSearchParams()
      const linkName = affiliateLinkName || UserAuthState.affiliateLinkName ||
        inviteLinkName || UserAuthState.inviteLinkName

      if (linkName) {
        if(userData?.userCurrent?.role === UserRoles.Tipster){
          PublicChannelState.adminJoinDisabled = true
          return
        }
        setLoading(true)
        const { followChannelByLinkName: data } = (
          await followChannelByLinkName({ variables: { internalName: linkName } })
        ).data
        PrivateChannelState.newChannelId = data.channelId
        localStorage.setItem('newChannelId', data.channelId)
        window.location.href = (new URL(ROUTES.channel.short, window.location.origin)).href
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  const followTipsterByLink = async () => {
    try {
      const { followLinkName } = AuthHelper.getSearchParams()
      const linkName = followLinkName || UserAuthState.followLinkName

      if (linkName) {
        setLoading(true)
        const tipster = await followUserByLinkName({ variables: { linkName } })
        window.location.href = (
          `${window.location.origin}${ROUTES.profile.short}/${tipster?.data?.followUserByLinkName?.id}`
        )
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  const isAllowed = useMemo(
    () => !!(id || guestMode) || initiateOnboarding,
    [ id, guestMode, initiateOnboarding ]
  )

  const entryScreenRedirect = useMemo(() => {
    if(UserRoles.Tipster == userType && hasOnboarded) return `${ROUTES.channel.short}?channelId=${ownedChannels[0]?.id}`
    if(initiateOnboarding || (isAllowed && !hasOnboarded)) return onboardingRedirectUrl(id, userType, onboardingStage)
    return ROUTES.feed.short
  }, [ id, initiateOnboarding, userType, onboardingStage, ownedChannels, isAllowed ])

  const SpecialRoutes = useMemo(() => {
    if(countryData?.countryByIp?.isBlacklisted) return <Route path="*" element={<BlacklistedCountryScreen />} />
    if(banned) return <Route path="*" element={<BannedScreen/>} />

    return (
      <>
        <Route
          index
          element={
            <ProtectedRoute redirectPath={entryScreenRedirect} isAllowed={!isAllowed}>
              <EntryScreen/>
            </ProtectedRoute>
          }
        />
        <Route element={<ProtectedRoute redirectPath={ROUTES.root} isAllowed={isAllowed && hasOnboarded} />}>
          <Route
            path={ROUTES.feed.short}
            element={<PublicChannelScreen/>}
          />
          <Route
            path={ROUTES.channel.short}
            element={<PrivateChannelScreen/>}
          />
          <Route
            path={ROUTES.appSettings.short}
            element={<AppSettingsScreen/>}
          />
          <Route
            path={ROUTES.contactForm.short}
            element={<ContactFormScreen/>}
          />
          <Route
            path={ROUTES.matchesList.short}
            element={<MatchesListScreen/>}
          />
          <Route
            path={ROUTES.savedTips.short}
            element={<SavedTipsScreen/>}
          />
          <Route
            path={ROUTES.notifications.short}
            element={<NotificationsSettingsScreen/>}
          />

          <Route
            path={ROUTES.notificationsDetails.short}
            element={<NotificationsSettingsDetailsScreen/>}
          />
          <Route
            path={ROUTES.inbox.short}
            element={<InboxScreen/>}
          />
          <Route
            path={ROUTES.profile.full}
            element={<Profile/>}
          />
          <Route
            path={ROUTES.channelProfile.full}
            element={<ChannelProfileScreen/>}
          />
          <Route
            path={ROUTES.matchesVoted.short}
            element={<MatchesVotedScreen/>}
          />
          <Route
            path={ROUTES.payment.short}
            element={<PaymentScreen/>}
          />
          <Route
            path={ROUTES.paymentResult.short}
            element={<PaymentResultScreen/>}
          />
          <Route
            path={ROUTES.userSubscriptions.short}
            element={<UserSubscriptions/>}
          />
          <Route
            path={ROUTES.tipsterSubscriptions.short}
            element={<TipsterSubscriptions/>}
          />
          <Route
            path={ROUTES.tipsterSubscriberList.short}
            element={<TipsterSubscribersList/>}
          />
          {/* <Route
            path={ROUTES.content.full}
            element={<CommentsScreen/>}
          /> */}
        </Route>
        <Route element={<ProtectedRoute redirectPath={entryScreenRedirect} isAllowed={!hasOnboarded || !isAllowed} />}>
          <Route path={ROUTES.login.asParent} element={<LoginRoutes/>}/>
          <Route path={ROUTES.tipsterOnboarding.asParent} element={<TipsterOnboardingRoutes/>}/>
          <Route path={ROUTES.magicLink.short} element={<MagicLinkScreen/>}/>
          <Route path={ROUTES.magicLinkSent.short} element={<MagicLinkSentScreen/>}/>

        </Route>
        <Route path="*" element={<Navigate to={ROUTES.feed.short} replace/>}/>
      </>
    )
  }, [ countryData, banned, isAllowed, entryScreenRedirect, hasOnboarded, initiateOnboarding ])

  return loading? <Screen title="Votebetting" loading/>: <ApplicationStyled>
    <InstallAppContext.Provider value={installEvent}>
      <Toaster />
      <BrowserRouter>
        <Suspense fallback={<Screen title="Votebetting" loading/>}>
          <SignupPromptModal />
          <Popup
            open={hasError}
            onClose={() => {
              AuthHelper
                .logOut()
                .then(() => {
                  resetCurrentUserState()
                  setHasError(!hasError)
                })
                .catch(() => {
                  resetCurrentUserState()
                  setHasError(!hasError)
                })
            }}
            headerText="Something went wrong"
            closeButtonText="Refresh"
          />
          <OnboardingAccountSwitcher />
          <Routes>
            <Route path={ROUTES.install.short} element={<InstallScreen/>} />
            <Route path={ROUTES.legal.short} element={<LegalScreen/>} />
            <Route
              path={ROUTES.content.full}
              element={<CommentsScreen/>}
            />
            {SpecialRoutes}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </InstallAppContext.Provider>
  </ApplicationStyled>
}

const ApplicationStyled = styled.div``

export default Application